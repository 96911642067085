import classes from "./AddLiquidity.module.css";
import {
  BackLink,
  InfoRow,
  SwapIcon,
  Tooltip,
  useModalByName,
  Processing,
  TokenPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import { useTonTokensPanel } from "../../../../TokensPanel";
import classNames from "classnames";
import { SelectTokenModal } from "../../../../Tokens";
import { ConnectWalletModal } from "../../../../Account/components/ConnectWalletModal";
import { useNavigate } from "react-router-dom";
import { FirstLiquidity } from "../../../../../components/FirstLiquidity";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";
import { useSimulateProvideLiquidity } from "../../hooks/useSimulateProvideLiquidity";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { useTonAddress } from "@tonconnect/ui-react";
import { AddTransactionModal } from "../AddTransactionModal";
import { formatUnits } from "ethers";

export const AddLiquidity = ({ pair }: { pair?: string }) => {
  const navigate = useNavigate();
  const { modal, closeModal, changeModal } = useModalByName();
  const { tokenList } = useTokenListContext();
  const tonAddress = useTonAddress(false);
  const [inputFrom, setInputFrom] = useState("");
  const [, setInputTo] = useState("");
  const {
    tokens,
    selectToken,
    token0Balance,
    token1Balance,
    error,
    setTokens,
  } = useTonTokensPanel(
    tonAddress,
    modal,
    inputFrom,
    tokenList,
    pair?.split("-"),
  );

  const estimatedPool = useSimulateProvideLiquidity(
    tokens[0],
    tokens[1],
    inputFrom,
    "0",
  );

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classes.Headline}>
        <BackLink text="Add liquidity" onClick={() => navigate(-1)} />
        <Tooltip text="When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time." />
      </div>
      <TokensPanel
        setTokens={() => setTokens([tokens[1], tokens[0]])}
        isLiquidity
      >
        <TokenPanel
          title="Input"
          value={inputFrom}
          setValue={setInputFrom}
          token0={tokens[0]}
          tokenBalance={getShowBalance(token0Balance, tokens[0]?.decimals)}
          tokenSelectHandle={() => changeModal("token-from")}
        />
        <TokenPanel
          title="Input"
          value={formatUnits(
            estimatedPool.liquidity?.input1 ?? 0n,
            tokens[1]?.decimals,
          )}
          token0={tokens[1]}
          tokenBalance={getShowBalance(token1Balance, tokens[1]?.decimals)}
          tokenSelectHandle={() => changeModal("token-to")}
          setValue={
            estimatedPool?.isFirstLiquidityProvider ? setInputTo : undefined
          }
        />
      </TokensPanel>
      {estimatedPool?.isFirstLiquidityProvider && <FirstLiquidity />}
      {tokens[0] &&
        tokens[1] &&
        (estimatedPool.loading ? (
          <div style={{ width: "100%" }}>
            <Skeleton height={80} />
          </div>
        ) : (
          estimatedPool.liquidity && (
            <div className="card bordered">
              <InfoRow
                title="Rates"
                value={
                  <>
                    1 {tokens[0].symbol} = {"0"} {tokens[1].symbol}
                    <SwapIcon />
                  </>
                }
              />
              <InfoRow
                title="Share of pool"
                value={`${((estimatedPool.liquidity?.userShare ?? 0) * 100).toFixed(2)}%`}
              />
            </div>
          )
        ))}
      {tonAddress ? (
        <button
          className={"btn block"}
          onClick={() => changeModal("add")}
          disabled={
            !!error ||
            (estimatedPool.liquidity?.input1 ?? 0n) > BigInt(token1Balance) ||
            modal === "add"
          }
        >
          {error ??
            ((estimatedPool.liquidity?.input1 ?? 0n) > BigInt(token1Balance) ? (
              `Insufficient ${tokens[1]?.symbol} balance`
            ) : modal === "add" ? (
              <Processing />
            ) : (
              "Supply"
            ))}
        </button>
      ) : (
        <button className={"btn block"} onClick={() => changeModal("wallet")}>
          Connect wallet
        </button>
      )}
      {(modal === "token-to" || modal === "token-from") && (
        <SelectTokenModal
          toggleModal={closeModal}
          selectToken={selectToken}
          token={modal === "token-to" ? tokens[1] : tokens[0]}
        />
      )}
      {modal === "wallet" && <ConnectWalletModal toggleModal={closeModal} />}
      {modal === "add" && tokens[0] && tokens[1] && estimatedPool.liquidity && (
        <AddTransactionModal
          token0={tokens[0]}
          token1={tokens[1]}
          token0Amount={inputFrom}
          token1Amount={formatUnits(
            estimatedPool.liquidity.input1 ?? "0",
            tokens[1]?.decimals,
          )}
          lpTokenAmount={estimatedPool.liquidity.lpBalance ?? "0"}
          accountId={tonAddress}
          poolShare={((estimatedPool.liquidity.userShare ?? 0) * 100).toFixed(
            2,
          )}
          rates={"0"}
          onCloseButtonClick={closeModal}
        />
      )}
    </div>
  );
};
