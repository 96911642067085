import { useQuery } from "@tanstack/react-query";
import { IPool } from "../../../Pools/_parachain/interfaces/pool";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getTokenInfoBySymbol } from "../../../Tokens";

export const useHarvestReward = (
  api: ApiPromise | undefined,
  account: Account,
  pool: IPool | undefined,
  rewardTokens: string[],
) => {
  const {
    data: rewards,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "fetchHarvestReward",
      account,
      pool?.userShare.toString(),
      rewardTokens,
    ],
    queryFn: async () => {
      if (pool && account && api) {
        const farms = new FarmsService(api);
        const tokens = rewardTokens
          .map((t) => getTokenInfoBySymbol(t))
          .filter((f) => !!f);

        return await Promise.all(
          (tokens as IToken[]).map(async (token) => {
            const rewardValue = await farms.getHarvestRewards(
              account,
              pool.dexShare,
              token.symbol,
            );
            return { token, value: rewardValue };
          }),
        );
      }
      return [];
    },
    enabled: !!pool && !!account && !!api,
    staleTime: 5 * 1000,
  });

  return { rewards: rewards || [], loading: isLoading, error };
};
