import { useQuery } from "@tanstack/react-query";
import { IToken } from "@curiodao/capital-dex-sdk";
import { PoolsService } from "../services/pools.service";

export const useSimulateRemove = (
  token0: IToken,
  token1: IToken,
  lpAmount: string,
) => {
  const { data: poolAmounts } = useQuery({
    queryKey: ["simulateRemove", token0.address, token1.address, lpAmount],
    queryFn: async () => {
      const poolService = new PoolsService();
      return await poolService.getExpectedLiquidity(token0, token1, lpAmount);
    },
    enabled: !!lpAmount.length,
  });

  return poolAmounts;
};
