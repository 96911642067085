import { useQuery } from "@tanstack/react-query";
import { useWalletContext } from "../../../common/context/Wallet";
import { useTokenBalance } from "../../Tokens/_parachain/hooks/useTokenBalance";
import { getTokenInfoBySymbol } from "../../Tokens";
import { StakingService } from "@curiodao/capital-dex-sdk/polkadot";

export const useStakingBalanceInfo = () => {
  const { account, api } = useWalletContext();
  const { balance } = useTokenBalance(getTokenInfoBySymbol("CGT"));

  const {
    data: stakingInfo,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["stakingBalanceInfo", account, balance],
    queryFn: async () => {
      if (account && balance !== "-" && api) {
        const staking = new StakingService(api, account);
        const staked = await staking.staked();
        const unstaking = await staking.unstaking();
        let lockedCount = 0n;

        if (unstaking && !unstaking.isEmpty) {
          unstaking.forEach((value) => {
            if (value) {
              lockedCount += value.toBigInt();
            }
          });
        }

        return {
          staked: staked?.value.amount?.toBigInt() || 0n,
          locked: lockedCount,
        };
      }
      return { staked: 0n, locked: 0n };
    },
    enabled: !!account && balance !== "-" && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return {
    balance,
    staked: stakingInfo?.staked ?? 0n,
    locked: stakingInfo?.locked ?? 0n,
    maxStake:
      balance !== undefined && stakingInfo?.locked !== undefined
        ? BigInt(balance) + stakingInfo.locked
        : 0n,
    loading,
    error,
  };
};
