import { useQuery } from "@tanstack/react-query";
import { SwapService } from "../services/swap.service";
import { IToken } from "@curiodao/capital-dex-sdk";
import { formatUnits } from "ethers";

export const useSimulateSwap = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  amount: string,
) => {
  const {
    data: trade,
    isLoading: loading,
    isError: noLiquidity,
  } = useQuery({
    queryKey: ["simulateSwap", token0?.symbol, token1?.symbol, amount],
    queryFn: async () => {
      if (token0 && token1 && amount.length) {
        const swapService = new SwapService();
        const data = await swapService.getExpectedOutputs(
          token0,
          token1,
          amount,
        );

        if (data) {
          const { out, priceImpact, minReceived } = data;
          const swapRate =
            Number(formatUnits(out.jettonToReceive, token1.decimals)) /
            Number(amount);

          return {
            min_ask_units: minReceived.toFixed(6),
            ask_units: out.jettonToReceive.toString(),
            swap_rate: swapRate.toString(),
            fee_units: "",
            fee_percent: "0.3",
            offer_units: "",
            pool_address: "",
            router_address: "",
            price_impact: priceImpact.toFixed(3),
            slippage_tolerance: "0.001",
          };
        }
        throw new Error("No Liquidity");
      }
      return undefined;
    },
    enabled: !!token0 && !!token1 && !!amount,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
    retry: false,
  });

  return {
    trade,
    loading,
    noLiquidity,
  };
};
