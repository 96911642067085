import { useQuery } from "@tanstack/react-query";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";

export const useAPY = (pool: IFarmPool) => {
  const { api } = useWalletContext();

  const {
    data: apy,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["fetchAPY", pool.token0, pool.token1],
    queryFn: async () => {
      if (api) {
        const farms = new FarmsService(api);
        return await farms.getFarmAPY(pool.token0, pool.token1);
      }
      return "0";
    },
    enabled: !!api,
    staleTime: 5 * 1000,
  });

  return { apy: apy || "0", loading: isLoading, error };
};
