import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useQuery } from "@tanstack/react-query";

export const useEthAddLiquidityParams = (
  amountIn: string,
  tokenA: IToken | undefined,
  tokenB: IToken | undefined,
) => {
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, core } = useNetworkContext();

  const {
    data: addLiquidityParams,
    isLoading,
    isPending,
    error,
  } = useQuery({
    queryKey: [
      "fetchAddLiquidityParams",
      amountIn,
      tokenA?.symbol,
      tokenB?.symbol,
      chainId,
    ],
    queryFn: async () => {
      if (core && amountIn.length && tokenA && tokenB) {
        const poolsService = new PoolsService();
        return await poolsService.getAddLiquidityParams(
          tokenList,
          amountIn,
          chainId,
          core.networkCredentials.rpcUrl,
          tokenA,
          tokenB,
          walletProvider,
        );
      }
      return undefined;
    },
    enabled:
      !!core &&
      !!amountIn.length &&
      !!tokenA &&
      !!tokenB &&
      !!walletProvider &&
      !!chainId,
    staleTime: 5 * 1000,
  });

  return { addLiquidityParams, loading: isLoading, pending: isPending, error };
};
