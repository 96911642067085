import { useQuery } from "@tanstack/react-query";
import { IToken } from "@curiodao/capital-dex-sdk";
import { usePositions } from "./usePositions";
import { useTokenListContext } from "../../../../contexts/TokenList";

export const usePoolByTokens = (
  token0: IToken,
  token1: IToken,
  accountId: string,
) => {
  const { tokenList } = useTokenListContext();
  const { pools } = usePositions(accountId, tokenList);

  const { data: pool } = useQuery({
    queryKey: ["poolByTokens", token0.address, token1.address, pools],
    queryFn: () => {
      return pools?.find(
        (pool) =>
          (pool.token0?.address === token0.address &&
            pool.token1?.address === token1.address) ||
          (pool.token0?.address === token1.address &&
            pool.token1?.address === token0.address),
      );
    },
    enabled: !!pools && !!token0 && !!token1,
  });

  return { pool };
};
