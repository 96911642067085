import { useMutation } from "@tanstack/react-query";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { IFarmPool, FarmService } from "@curiodao/capital-dex-sdk/evm";

export const useStake = (pool: IFarmPool) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core } = useNetworkContext();

  const { mutate: stake, isPending: isStaking } = useMutation({
    mutationFn: async (amount?: bigint) => {
      if (core && pool && walletProvider && amount) {
        const farm = new FarmService(pool);
        await farm.stake(walletProvider, amount);
      }
    },
  });

  const { mutate: unstake, isPending: isUnstaking } = useMutation({
    mutationFn: async (amount?: bigint) => {
      if (core && pool && walletProvider && amount) {
        const farm = new FarmService(pool);
        await farm.unstake(walletProvider, amount);
      }
    },
  });

  return {
    stake,
    unstake,
    isStaking,
    isUnstaking,
  };
};
