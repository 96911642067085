import { useQuery } from "@tanstack/react-query";
import { isEthereum, useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";

export const useAPY = (pool: IFarmPool) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core, network } = useNetworkContext();

  const {
    data: apy,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["fetchAPY", pool, core?.networkCredentials.rpcUrl],
    queryFn: async () => {
      if (core?.networkCredentials.rpcUrl) {
        const farmService = new FarmService(pool);
        return await farmService.getOldAPY(
          isEthereum(network),
          core.blocksPerYear,
          core?.networkCredentials.rpcUrl,
          walletProvider,
        );
      }
      return undefined;
    },
    enabled: !!core?.networkCredentials.rpcUrl && !!walletProvider,
    staleTime: 5 * 1000,
  });

  return { apy, loading, error };
};
