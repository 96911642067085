import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { useQuery } from "@tanstack/react-query";

export const usePoolsInfo = (account: string | undefined) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId } = useNetworkContext();
  const { tokenList } = useTokenListContext();

  const {
    data: poolsInfo,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["fetchPoolsInfo", account, chainId],
    queryFn: async () => {
      if (account && walletProvider && chainId) {
        const poolsService = new PoolsService();
        return await poolsService.getUserPositions(
          tokenList.filter((token) => token.symbol !== "BTC"),
          account,
          chainId,
          walletProvider,
        );
      }
      return [];
    },
    enabled: !!account && !!walletProvider && !!chainId,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return {
    positions: poolsInfo?.filter(
      (position) => position !== undefined,
    ) as IPosition[],
    loading: isLoading,
    resError: error?.message,
  };
};
