import { useMutation, useQuery } from "@tanstack/react-query";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";

export const useHarvest = (pool: IFarmPool, account?: string) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core } = useNetworkContext();

  const {
    data: rewards = 0n,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["fetchHarvest", core, pool, account],
    queryFn: async () => {
      if (core && pool && account && walletProvider) {
        const farms = new FarmService(pool);
        return await farms.getPoolReward(account, walletProvider);
      }
      return 0n;
    },
    enabled: !!core && !!pool && !!account && !!walletProvider,
    staleTime: 5 * 1000,
    refetchInterval: 10000,
  });

  const { mutate: harvest, isPending: isHarvesting } = useMutation({
    mutationFn: async () => {
      if (core && pool && walletProvider) {
        const farm = new FarmService(pool);
        await farm.harvest(walletProvider);
      }
    },
  });

  return {
    harvest,
    isHarvesting,
    rewards,
    loading,
    error,
  };
};
