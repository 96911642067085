import { IPool } from "../interfaces/pool";
import { useWalletContext } from "../../../../common/context/Wallet";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useQuery } from "@tanstack/react-query";

export const usePool = (
  pool: IPool | undefined,
  account: string | null | undefined,
) => {
  const { api } = useWalletContext();

  const {
    data: poolInfo,
    error,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: ["fetchPoolInfo", pool?.pair, account],
    queryFn: async () => {
      if (pool && account && api) {
        const poolService = new PoolsService(api, account);
        return await poolService.getPoolBalancesByUser(pool);
      }
    },
    enabled: !!pool && !!account && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return {
    poolInfo,
    loading: isLoading,
    resError: error?.message,
    isPending,
  };
};

export const usePoolByTokens = (
  token0: IToken,
  token1: IToken,
  account: string | undefined,
) => {
  const { api } = useWalletContext();

  const { data: pool } = useQuery({
    queryKey: ["fetchPoolByTokens", token0?.symbol, token1?.symbol, account],
    queryFn: async () => {
      if (account && api) {
        const poolService = new PoolsService(api, account);
        const { tokenA, tokenB, dexShare, pair } = poolService.getTokens(
          token0,
          token1,
        );
        const userShare = await poolService.getUserShare(token0, token1);
        return {
          pair,
          dexShare,
          userShare,
          tokenA,
          tokenB,
        };
      }
    },
    enabled: !!token0 && !!token1 && !!account && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return pool;
};
