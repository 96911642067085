import { useQuery } from "@tanstack/react-query";
import { useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";

export const usePoolBalances = (pool: IFarmPool, account?: string) => {
  const { core } = useNetworkContext();
  const { walletProvider } = useWeb3ModalProvider();

  const {
    data: balances,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["fetchPoolBalances", core, pool, account],
    queryFn: async () => {
      if (core && pool && account && walletProvider) {
        const farms = new FarmService(pool);
        const staked = await farms.getStaked(account, walletProvider);
        const userBalance = await farms.getBalance(account, walletProvider);
        return { staked, balance: userBalance };
      }
      return { staked: 0n, balance: 0n };
    },
    enabled: !!core && !!pool && !!account && !!walletProvider,
    staleTime: 5 * 1000,
    refetchInterval: 10000,
  });

  return {
    staked: balances?.staked ?? 0n,
    balance: balances?.balance ?? 0n,
    loading,
    error,
  };
};
