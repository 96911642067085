import { useWalletContext } from "../../../../common/context/Wallet";
import { SwapService } from "@curiodao/capital-dex-sdk/polkadot";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useQuery } from "@tanstack/react-query";

export const useSwapParams = (
  tokens: (IToken | undefined)[],
  inputFrom: string,
) => {
  const { api } = useWalletContext();

  const {
    data: swapParams,
    error,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: [
      "fetchSwapParams",
      tokens[0]?.symbol,
      tokens[1]?.symbol,
      inputFrom,
    ],
    queryFn: async () => {
      if (api && tokens[0] && tokens[1] && inputFrom) {
        const swap = new SwapService(api);
        return await swap.getSwapParams([tokens[0], tokens[1]], inputFrom);
      }
    },
    enabled: !!tokens[0] && !!tokens[1] && !!inputFrom && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  const outputAmount = swapParams?.output.balance.toNumber().toFixed(4) ?? "";
  const priceImpact = swapParams?.priceImpact.toNumber().toFixed(4) ?? "";
  const fee = swapParams?.exchangeFee.toString() ?? "";
  const price = swapParams
    ? (
        swapParams.output.balance.toNumber() /
        swapParams.input.balance.toNumber()
      ).toFixed(4)
    : "";

  return {
    outputAmount,
    priceImpact,
    fee,
    price,
    resError: error?.message,
    swapParams,
    loading: isLoading,
    isPending,
  };
};
