import { useQuery } from "@tanstack/react-query";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { IPool } from "../../../Pools/_parachain/interfaces/pool";
import { displayValue, toHuman } from "../../../../common/libs/support";

export const useStaked = (
  pool: IPool | undefined,
  api: ApiPromise | undefined,
  account: Account,
) => {
  const {
    data: staked,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["fetchStaked", account, pool?.userShare.toString()],
    queryFn: async () => {
      if (pool && account && api) {
        const farms = new FarmsService(api);
        const res = await farms.getStaked(pool, account);
        return {
          dsp: displayValue(res[0].toBigInt()),
          str: toHuman(res[0].toBigInt()),
          bn: res[0].toBigInt(),
        };
      }
      return undefined;
    },
    enabled: !!pool && !!account && !!api,
    staleTime: 5 * 1000,
  });

  return { staked, loading: isLoading, error };
};
