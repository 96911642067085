import { useQuery } from "@tanstack/react-query";
import { useNetworkContext } from "../../../Network";
import { formatUnits } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmsService } from "@curiodao/capital-dex-sdk/evm";

export const useFarmTotalSupply = () => {
  const { core } = useNetworkContext();
  const { walletProvider } = useWeb3ModalProvider();

  const {
    data: info,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["fetchFarmTotalSupply", core?.cgt],
    queryFn: async () => {
      if (core?.cgt) {
        const farmsService = new FarmsService(core.cgt);
        const totalSupply = await farmsService.getTotalSupply(
          core.networkCredentials.rpcUrl,
          walletProvider,
        );
        return formatUnits(totalSupply);
      }
      return undefined;
    },
    enabled: !!core?.cgt && !!walletProvider,
    staleTime: 5 * 1000,
  });

  return { info, loading, error };
};
