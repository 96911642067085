import { useQuery } from "@tanstack/react-query";
import { IToken } from "@curiodao/capital-dex-sdk";
import { PoolsService } from "../services/pools.service";

export const useSimulateProvideLiquidity = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  inputFrom: string | undefined,
  inputTo: string | undefined,
  noCalculate?: boolean,
) => {
  const { data: liquidity, isLoading: loading } = useQuery({
    queryKey: [
      "simulateProvideLiquidity",
      token0?.address,
      token1?.address,
      inputFrom,
      inputTo,
    ],
    queryFn: async () => {
      const poolsService = new PoolsService();
      if (token0 && token1 && inputFrom && inputTo) {
        const res = await poolsService.getExpectedTokens(
          token0,
          token1,
          inputFrom,
          inputTo,
        );

        return {
          lpBalance: res?.lpBalance,
          userShare: res?.userShare,
          input1: res?.input1,
        };
      }
    },
    enabled: !!token0 && !!token1 && !!inputFrom && !!inputTo && !noCalculate,
  });

  const isFirstLiquidityProvider = !liquidity;

  return {
    liquidity,
    loading,
    isFirstLiquidityProvider,
  };
};
