import { useQuery } from "@tanstack/react-query";
import { PoolsService } from "../services/pools.service";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getTokenByAddress } from "../../../../libs";
import { tonDexClient } from "../../../../config/clients";
import { JettonsService } from "../../../Tokens";

export const usePositions = (accountId: string, tokenList: IToken[]) => {
  const { data: balances } = useQuery({
    queryKey: ["fetchBalances", accountId],
    queryFn: () => new JettonsService(tonDexClient).fetchBalances(accountId),
    enabled: !!accountId,
  });

  const { data: pools, isLoading: loading } = useQuery({
    queryKey: ["positions", accountId, balances, tokenList],
    queryFn: async () => {
      if (balances) {
        const poolsService = new PoolsService();
        const res = await poolsService.getPositions(balances, tonDexClient);

        return res?.map((position) => {
          const factor = Number(position.lp_balance) / position.lp_total_supply;
          return {
            token0: getTokenByAddress(position.token0_address, tokenList),
            token1: getTokenByAddress(position.token1_address, tokenList),
            address: position.address,
            token0Amount: (Number(position.reserve0) * factor).toString(),
            token1Amount: (Number(position.reserve1) * factor).toString(),
            totalSupply: position.lp_total_supply.toString(),
            userShare: position.lp_balance || "0",
            percent: factor * 100,
          };
        });
      }
    },
    enabled: !!accountId && !!balances && !!tokenList,
  });

  return { pools, loading };
};
