import { useQuery } from "@tanstack/react-query";
import { useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { IFarmInfo } from "../../../../common/_evm/interfaces/IFarmInfo";
import { RewardsService } from "@curiodao/capital-dex-sdk/evm";

export const useEarned = (farming?: IFarmInfo[], account?: string) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core } = useNetworkContext();

  const {
    data: rewards = 0n,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["fetchEarned", farming, account],
    queryFn: async () => {
      if (walletProvider && core && farming && account) {
        const farms = new RewardsService(account);
        return farms.getEarned(farming, walletProvider);
      }
      return 0n;
    },
    enabled: !!walletProvider && !!core && !!farming && !!account,
    staleTime: 5 * 1000,
  });

  return { rewards, loading, error };
};
