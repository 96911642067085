import { useQuery } from "@tanstack/react-query";
import { TokenService } from "../services/Token.service";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IToken } from "@curiodao/capital-dex-sdk";
import { BTC } from "../../../../contexts/TokenList/constants";
import { useRef } from "react";

export const useTokenBalance = (token: IToken | undefined) => {
  const { api, account } = useWalletContext();

  const lastBalanceRef = useRef("0");

  const { data: balance = lastBalanceRef.current, isLoading: loading } =
    useQuery({
      queryKey: ["tokenBalance", account, token?.symbol],
      queryFn: async () => {
        if (account && token && api && token.symbol !== BTC.symbol) {
          const tokens = new TokenService(account, api);

          let newBalance;
          if (token.symbol === "CGT" || !token.symbol) {
            const cgtBalance = await tokens.getCGTBalance();
            newBalance = cgtBalance.toString();
          } else {
            const tokenBalance = await tokens.getBalance(token);
            newBalance = tokenBalance.free.toString();
          }

          if (newBalance !== "0") {
            lastBalanceRef.current = newBalance;
          }

          return newBalance;
        }
        return lastBalanceRef.current;
      },
      enabled: !!account && !!token && !!api && token.symbol !== BTC.symbol,
      staleTime: 5 * 1000,
      refetchInterval: 15000,
    });

  return { balance, loading };
};
