import { useWalletContext } from "../../../../common/context/Wallet";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { POOlS } from "../constants/pools";
import { useQuery } from "@tanstack/react-query";

export const usePools = () => {
  const { api, account } = useWalletContext();

  const {
    data: pools,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["fetchPools", account],
    queryFn: async () => {
      if (account && api) {
        const poolsService = new PoolsService(api, account);
        return await poolsService.getPools(POOlS);
      }
    },
    enabled: !!account && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return { pools, loading: isLoading, resError: error?.message };
};
