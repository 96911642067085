import { useQuery } from "@tanstack/react-query";
import { JettonsService } from "../../../Tokens";
import { tonApiClient } from "../../../../config/clients";

export const useFarmTotalSupply = () => {
  const { data: totalSupply = "0" } = useQuery({
    queryKey: ["farmTotalSupply"],
    queryFn: async () => {
      const jettons = new JettonsService(tonApiClient);
      return await jettons.getTotalSupply();
    },
  });

  return { totalSupply };
};
