import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { useQuery } from "@tanstack/react-query";

export const useRemoveLiquidityReturns = (
  position: IPosition | undefined,
  inputFrom: string,
) => {
  const { address } = useWeb3ModalAccount();

  const { data, isPending, error } = useQuery({
    queryKey: ["fetchRemoveLiquidityReturns", position, inputFrom, address],
    queryFn: async () => {
      if (position && inputFrom) {
        const poolsService = new PoolsService();
        return await poolsService.getRemoveReturns(position, inputFrom);
      }
      return undefined;
    },
    enabled: !!position && !!inputFrom,
    staleTime: 5 * 1000,
  });

  return {
    tokenAReturns: data?.tokenAReturns ?? 0,
    tokenBReturns: data?.tokenBReturns ?? 0,
    pending: isPending,
    error,
  };
};
