import { useQuery } from "@tanstack/react-query";
import { useWalletContext } from "../../../common/context/Wallet";
import { SystemInfoService, toToken } from "@curiodao/capital-dex-sdk/polkadot";

export const useRewardRate = (value: string, activeTab: string) => {
  const { api } = useWalletContext();

  const { data: rewardData, isLoading: loading } = useQuery({
    queryKey: ["rewardRate", value, activeTab],
    queryFn: async () => {
      if (api) {
        const systemInfo = new SystemInfoService(api);
        const minStake = systemInfo.minDelegatorStake();
        const rewardRate = await systemInfo.rewardRate(
          toToken(value).toString(),
          activeTab === "stake",
        );
        return { minStake, rewardRate };
      }
      return { minStake: undefined, rewardRate: undefined };
    },
    enabled: !!api,
    staleTime: 5 * 1000,
  });

  return {
    rewardRate: rewardData?.rewardRate,
    minStake: rewardData?.minStake,
    loading,
  };
};
