import { useQuery } from "@tanstack/react-query";
import { TokenService } from "@curiodao/capital-dex-sdk/evm";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useRef } from "react";

export const useTokenBalance = (token: IToken | undefined) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const lastBalanceRef = useRef("0");

  const { data: balance = lastBalanceRef.current, isLoading: loading } =
    useQuery({
      queryKey: ["tokenBalance", address, token?.symbol],
      queryFn: async () => {
        if (address && token && walletProvider) {
          const tokens = new TokenService(token, walletProvider);
          const fetchedBalance = await tokens.getBalance(address);
          const newBalance = fetchedBalance.toString();

          if (newBalance !== "0") {
            lastBalanceRef.current = newBalance;
          }

          return newBalance;
        }
        return lastBalanceRef.current;
      },
      enabled: !!address && !!token && !!walletProvider,
      staleTime: 5 * 1000,
      refetchInterval: 15000,
    });

  return { balance, loading };
};
