import { useMutation } from "@tanstack/react-query";
import { useWalletContext } from "../../../common/context/Wallet";
import { SystemInfoService } from "@curiodao/capital-dex-sdk/polkadot";

export const useStaking = () => {
  const { account, injector, api } = useWalletContext();

  const stakeMutation = useMutation({
    mutationFn: async ({
      amount,
      staked,
      clean,
    }: {
      amount: string;
      staked?: bigint;
      clean?: () => void;
    }) => {
      if (account && injector && staked !== undefined && api) {
        const systemInfo = new SystemInfoService(api);
        const unsub = await (
          await systemInfo.stake(amount, staked)
        ).signAndSend(account, { signer: injector.signer }, ({ status }) => {
          if (status.isFinalized || status.isInBlock) {
            unsub();
            if (clean) {
              clean();
            }
          }
        });
      }
    },
  });

  const removeMutation = useMutation({
    mutationFn: async ({
      amount,
      staked,
      clean,
    }: {
      amount: string;
      staked?: bigint;
      clean?: () => void;
    }) => {
      if (account && injector && staked && api) {
        const systemInfo = new SystemInfoService(api);
        const unsub = await systemInfo
          .remove(amount, staked)
          .signAndSend(account, { signer: injector.signer }, ({ status }) => {
            if (status.isFinalized || status.isInBlock) {
              unsub();
              if (clean) {
                clean();
              }
            }
          });
      }
    },
  });

  return {
    stake: stakeMutation.mutateAsync,
    remove: removeMutation.mutateAsync,
    stakePending: stakeMutation.isPending,
    removePending: removeMutation.isPending,
  };
};
