import { useQuery } from "@tanstack/react-query";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { displayValue, toHuman } from "../../../../common/libs/support";

export const useTotalIssuance = (api: ApiPromise | undefined) => {
  const {
    data: totalIssuance,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["fetchTotalIssuance"],
    queryFn: async () => {
      if (api) {
        const farms = new FarmsService(api);
        const res = await farms.getTotalIssuance();
        return {
          str: toHuman(res.toBigInt(), 18),
          dsp: displayValue(res.toBigInt(), 18),
          bn: res.toBigInt(),
        };
      }
      return undefined;
    },
    enabled: !!api,
    staleTime: 5 * 1000,
  });

  return { totalIssuance, loading: isLoading, error };
};
