import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService, getUniswapToken } from "@curiodao/capital-dex-sdk/evm";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useQuery } from "@tanstack/react-query";

export const usePoolInfoByTokens = (
  account: string | undefined,
  token0: IToken | undefined,
  token1: IToken | undefined,
) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId } = useNetworkContext();
  const { tokenList } = useTokenListContext();

  const {
    data: poolInfo,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "fetchPoolInfoByTokens",
      account,
      token0?.symbol,
      token1?.symbol,
      chainId,
    ],
    queryFn: async () => {
      if (account && walletProvider && token0 && token1) {
        const poolsService = new PoolsService();
        const tokenA = getUniswapToken(token0, tokenList);
        const tokenB = getUniswapToken(token1, tokenList);

        if (tokenA && tokenB) {
          return await poolsService.getUserPosition(
            [tokenA, tokenB],
            tokenList,
            account,
            chainId,
            walletProvider,
          );
        }
      }
      return undefined;
    },
    enabled: !!account && !!walletProvider && !!token0 && !!token1 && !!chainId,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return {
    position: poolInfo as IPosition | undefined,
    loading: isLoading,
    resError: error?.message,
  };
};
