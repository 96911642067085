import { useQuery } from "@tanstack/react-query";
import { ApiPromise } from "@polkadot/api";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { PARACHAIN_POOLS } from "../constants/parachain-pools";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { displayValue, toHuman } from "../../../../common/libs/support";
import { IToken } from "@curiodao/capital-dex-sdk";

export const usePendingHarvest = (
  api: ApiPromise | undefined,
  account: Account,
  rewardToken: IToken | undefined,
) => {
  const {
    data: pendingHarvest,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["fetchPendingHarvest", account, rewardToken?.symbol],
    queryFn: async () => {
      if (account && api && rewardToken) {
        const farms = new FarmsService(api);
        const poolsService = new PoolsService(api, account);
        const pools = PARACHAIN_POOLS.filter(
          (pool) =>
            pool.token0.symbol === rewardToken.symbol ||
            pool.token1.symbol === rewardToken.symbol,
        );

        const rewards = await Promise.all(
          pools.map(async (farmPool) => {
            const pool = poolsService.getTokens(
              farmPool.token0,
              farmPool.token1,
            );
            return await farms.getHarvestRewards(
              account,
              pool.dexShare,
              rewardToken.symbol,
            );
          }),
        );

        const totalPendingHarvest = rewards
          .map((reward) => reward.bn)
          .reduce((total, value) => total + value);

        return {
          dsp: displayValue(totalPendingHarvest, rewardToken.decimals),
          bn: totalPendingHarvest,
          str: toHuman(totalPendingHarvest, rewardToken.decimals),
        };
      }
      return undefined;
    },
    enabled: !!account && !!api && !!rewardToken,
    staleTime: 5 * 1000,
  });

  return { pendingHarvest, loading: isLoading, error };
};
