import { useQuery } from "@tanstack/react-query";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { MulticallService } from "@curiodao/capital-dex-sdk/evm";

export const useTokenBalances = (
  tokens: IToken[],
  multicallAddress?: string,
) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { data: balancesList = [], isLoading } = useQuery({
    queryKey: ["tokenBalances", address, multicallAddress, tokens],
    queryFn: async () => {
      if (multicallAddress && walletProvider && address && tokens.length) {
        const multicall = new MulticallService();
        return await multicall.getBalances(
          tokens,
          address,
          walletProvider,
          multicallAddress,
        );
      }
      return [];
    },
    enabled:
      !!multicallAddress && !!walletProvider && !!address && tokens.length > 0,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return { balancesList, loading: isLoading };
};
