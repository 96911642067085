import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useWalletContext } from "../../../common/context/Wallet";
import {
  StakingService,
  SystemInfoService,
} from "@curiodao/capital-dex-sdk/polkadot";

export const useStakingStatistics = () => {
  const { account, injector, api } = useWalletContext();

  const [claimPending, setClaimPending] = useState(false);
  const [unlockPending, setUnlockPending] = useState(false);

  const {
    data: stakingStats,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["stakingStatistics", account],
    queryFn: async () => {
      if (account && injector && api) {
        const staking = new StakingService(api, account);
        const systemInfo = new SystemInfoService(api);

        const { delegatorRewardRate, delegatorStakingRate } =
          await systemInfo.stakingRates();

        const unstaking = await staking.unstaking();

        const currentBlock = await systemInfo.currentBlock();

        const unclaimedStakingRewards = await staking.unclaimedStakingRewards();

        return {
          rewardRate: delegatorRewardRate,
          stakingRate: delegatorStakingRate,
          unstaking,
          currentBlock,
          unclaimedRewards: unclaimedStakingRewards?.toBigInt(),
        };
      }
    },
    enabled: !!account && !!injector && !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  const claim = async () => {
    if (account && injector && api) {
      setClaimPending(true);
      try {
        const systemInfo = new SystemInfoService(api);
        const unsub = await systemInfo
          .claim()
          .signAndSend(
            account,
            { signer: injector.signer },
            ({ status, isError }) => {
              console.log(isError);
              if (status.isFinalized || status.isInBlock) {
                setClaimPending(false);
                unsub();
              }
              if (isError) {
                setClaimPending(false);
              }
            },
          );
      } catch (e) {
        console.error(e);
        setClaimPending(false);
      }
    }
  };

  const unlock = async () => {
    if (account && injector && api) {
      setUnlockPending(true);
      try {
        const staking = new StakingService(api, account);
        const unsub = await staking
          .unlock()
          .signAndSend(
            account,
            { signer: injector.signer },
            ({ status, isError }) => {
              console.log(isError);
              if (status.isFinalized || status.isInBlock) {
                setUnlockPending(false);
                unsub();
              }
              if (isError) {
                setUnlockPending(false);
              }
            },
          );
      } catch (e) {
        console.error(e);
        setUnlockPending(false);
      }
    }
  };

  return {
    claim,
    unlock,
    rewardRate: stakingStats?.rewardRate ?? 0n,
    stakingRate: stakingStats?.stakingRate ?? 0n,
    unclaimedRewards: stakingStats?.unclaimedRewards ?? 0n,
    unstaking: stakingStats?.unstaking,
    currentBlock: stakingStats?.currentBlock,
    claimPending,
    unlockPending,
    loading,
    error,
  };
};
