import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IToken } from "@curiodao/capital-dex-sdk";
import { POOlS } from "../constants/pools";
import { useQuery } from "@tanstack/react-query";

export const useEstimatedPool = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  inputFrom: string,
  inputTo: string,
  noCalculate?: boolean,
) => {
  const { account, api } = useWalletContext();

  const {
    data: addEstimate,
    error,
    isLoading,
  } = useQuery({
    queryKey: [
      "fetchEstimatedPool",
      account,
      token0?.symbol,
      token1?.symbol,
      inputFrom,
      inputTo,
    ],
    queryFn: async () => {
      if (
        account &&
        token0 &&
        token1 &&
        !!inputFrom.length &&
        !!inputTo.length &&
        !noCalculate &&
        api
      ) {
        const poolService = new PoolsService(api, account);
        return await poolService.getAddEstimate(
          token0,
          token1,
          inputFrom,
          inputTo,
          POOlS,
        );
      }
    },
    enabled:
      !!account &&
      !!token0 &&
      !!token1 &&
      !!inputFrom.length &&
      !!inputTo.length &&
      !noCalculate &&
      !!api,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return addEstimate
    ? {
        ...addEstimate,
        isFirstLiquidityProvider: addEstimate.incrementShare === 1,
        loading: isLoading,
        resError: error?.message,
      }
    : undefined;
};
