import {
  SwapService,
  getUniswapToken,
  getCombinationPairs,
} from "@curiodao/capital-dex-sdk/evm";
import { useNetworkContext } from "../../../Network";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useQuery } from "@tanstack/react-query";

export const useTrade = (
  amountIn: string,
  token0: IToken | undefined,
  token1: IToken | undefined,
  isWrapping: boolean,
) => {
  const { chainId, core } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();

  const {
    data: swapParams,
    error,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: [
      "fetchTradeParams",
      amountIn,
      token0?.symbol,
      token1?.symbol,
      isWrapping,
      chainId,
      core?.networkCredentials.rpcUrl,
    ],
    queryFn: async () => {
      const tokenA = getUniswapToken(token0, tokenList);
      const tokenB = getUniswapToken(token1, tokenList);

      if (
        tokenA &&
        tokenB &&
        core?.networkCredentials.rpcUrl &&
        !isWrapping &&
        amountIn.length
      ) {
        const pairsForRoute = getCombinationPairs(
          tokenList.filter((token) => token.symbol !== "BTC"),
        );
        const swapService = new SwapService();
        return await swapService.getEthSwapParams(
          tokenA,
          tokenB,
          amountIn,
          pairsForRoute,
          core.networkCredentials.rpcUrl,
          chainId,
          walletProvider,
          token0?.isNative,
          token1?.isNative,
        );
      }
      return undefined;
    },
    enabled:
      !!amountIn.length &&
      !!token0 &&
      !!token1 &&
      !isWrapping &&
      !!chainId &&
      !!core?.networkCredentials.rpcUrl &&
      !!walletProvider,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  return {
    trade: swapParams,
    resError: error?.message,
    loading: isLoading,
    isPending,
  };
};
